<template>
  <v-card rounded="lg" min-height="50vh">
    <v-sheet v-if="loading">
      <v-row>
        <v-col class="d-flex child-flex" xs="12" sm="12" md="12" lg="12" xl="12">
          <v-skeleton-loader type="table-heading" tile class="mb-n5"/>
        </v-col>
      </v-row>
      <v-row class="ml-2">
        <v-col class="d-flex child-flex" xs="12" sm="12" md="12" lg="12" xl="12">
          <v-row>
            <v-col class="d-flex child-flex mh-100" xs="1.5" sm="1.5" md="1.5" lg="1.5" xl="1.5" v-for="i in 7" :key="i">
              <v-skeleton-loader :type="`image@2, text@${47 - (i * 5)}, image, text@${(i * 5)}`" tile/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
        <v-sheet height="64" v-if="!loading">
          <v-toolbar
              flat
          >
            <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
            >
              {{ $t('label.today') }}
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
                bottom
                right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span>{{ $t(`label.${type}`) }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in ['month', 'week']" :key="index" @click="type = item" >
                  <v-list-item-title v-text="$t(`label.${item}`)"/>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="1015" :hidden="loading">
          <v-calendar
              :event-color="getEventColor"
              :events="events"
              :locale="locale"
              :type="type"
              v-model="focus"
              color="primary"
              ref="calendar"
              @click:date="viewDate"
              @click:day="viewDate"
              @click:event="showEvent"
              @change="loadEvents"
          />
        </v-sheet>
  </v-card>
</template>
<script>

import { DateTime } from "luxon";

export default {
  data: () => ({
    loading: true,
    focus: '',
    type: 'month',
  }),
  mounted () {
    if (!this.loading) {
      this.$refs.calendar.checkChange()
    }
    this.loadAllEvents();
  },
  watch: {
    focus(day) {
      const events = this.getTodayEvents();
      this.$emit('emitToAside', {day, events});
    },
    events(events) {
      this.setToday()
    }
  },
  methods: {
    setToday () {
      this.focus = DateTime.now().toFormat('y-MM-dd');
    },
    showEvent ({ nativeEvent, day}) {
      nativeEvent.stopPropagation()
      this.viewDate(day)
    },
    viewDate (day) {
      this.focus = day.date;
    },
    getEventColor (event) {
      return event.color
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    loadEvents({start, end}) {

      start = new Date(`${start.date}T00:00:00`)
      end = new Date(`${end.date}T23:59:59`)

      return this.events.filter(event => {
        const eventStart = new Date(`${event.start}`)
        const eventEnd = new Date(`${event.end}`)
        return eventStart >= start && eventEnd <= end;
      });
    },
    loadAllEvents() {
      this.loading = true;
      return this.$store.dispatch('events/load')
          .finally(() => { this.loading = false });
    },
    getTodayEvents() {
      return this.loadEvents({start: {date: this.focus}, end: {date: this.focus}})
    }
  },
  computed: {
    events() {
      return this.$store.getters['events/events'] || []
    },
    locale() {
      return this.$i18n.locale;
    }
  },
}
</script>
<style lang="scss">
.v-skeleton-loader__image {
  height: 50px;
}
</style>